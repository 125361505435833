import { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { isWebView, setScrollHidden } from "@/libs/utils";
import { publicDomain } from "@/libs/clientConfig";
import omit from "lodash/omit";
import { Auth } from "aws-amplify";
import getApi from "./useApi";
import { useApiRequestTracker } from "@/stores/api-request-tracker.store";

const api = getApi();
const useAuth = () => {
  const [isSignin, setIsSignin] = useState<boolean>(false);
  const [isSignup, setIsSignup] = useState<boolean>(false);
  const [isResetPassword, setIsResetPassword] = useState<boolean>(false);
  const [isAccountBannerVisible, setIsAccountBannerVisible] =
    useState<boolean>(false);
  const [isCreateListing, setIsCreateListing] = useState<boolean>(false);
  const [isListingAction, setIsListingAction] = useState<string>("");
  const [isDealNowDeal, setIsDealNowDeal] = useState<string>("");
  const [isDealNowDealStart, setIsDealNowDealStart] = useState<string>("");
  const [isFavMark, setIsFavMark] = useState<string>("");
  const [isVehicleHistory, setIsVehicleHistory] = useState<string>("");
  const [isLoanAction, setIsLoanAction] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [source, setSource] = useState<string>("");
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [coBrandedName, setCoBrandedName] = useState<string>("");

  const router = useRouter();

  const apiRequestTrackerStore = useApiRequestTracker();

  const getRedirectUrl = useCallback(
    (redirectParams: Record<string, any> = {}) => {
      if (isCreateListing) {
        return `/listing/steps?${new URLSearchParams(
          new URLSearchParams({
            ...omit(router.query, "slug"),
            ...redirectParams,
            isCreateListing: "true",
          }).toString()
        ).toString()}`;
      }
      if (redirectParams.inquiry) {
        return `/listing/steps?${new URLSearchParams(
          new URLSearchParams({
            inquiry: redirectParams.inquiry as string,
            isCreateListing: "true",
          }).toString()
        ).toString()}`;
      }

      if (redirectParams.isListingAction || isListingAction) {
        return `/listing/${redirectParams.isListingAction || isListingAction}`;
      }
      if (isDealNowDeal) {
        return `/deal-now/${isDealNowDeal}`;
      }
      if (isDealNowDealStart) {
        return `/deals?dealNowStart=${isDealNowDealStart}`;
      }
      if (redirectParams.isFavMark || isFavMark) {
        return `/listing/${redirectParams.isFavMark || isFavMark}`;
      }
      if (redirectParams.isVehicleHistory || isVehicleHistory) {
        return `/listing/${
          redirectParams.isVehicleHistory || isVehicleHistory
        }`;
      }
      if (redirectParams.isLoanAction || isLoanAction) {
        return `/listing/${redirectParams.isLoanAction || isLoanAction}`;
      }

      if (redirectParams.redirectUrl || redirectUrl) {
        return `${redirectParams.redirectUrl || redirectUrl}`;
      }

      return "/search-listings";
    },
    [
      isCreateListing,
      isListingAction,
      isDealNowDeal,
      isDealNowDealStart,
      isFavMark,
      isVehicleHistory,
      isLoanAction,
      redirectUrl,
      coBrandedName,
      router.query,
      isResetPassword,
    ]
  );

  const onLogin = useCallback(
    async (email: string, password: string) => {
      try {
        apiRequestTrackerStore.increment();
        await Auth.signOut();
        await Auth.signIn(email, password);
        const auth = await Auth.currentSession();
        const accessToken = auth.getAccessToken().getJwtToken();
        const idToken = auth.getIdToken().getJwtToken();
        const refreshToken = auth.getRefreshToken().getToken();
        const response = await api.signIn({
          accessToken,
          idToken,
          refreshToken,
        });
        return `https://app.${publicDomain()}/auth/initialize?redirectTo=${encodeURIComponent(
          getRedirectUrl()
        )}&code=${response.code}`;
      } catch (err: any) {
        let message = "There was a problem signing in to your account.";
        if (
          err.name === "UserNotFoundException" ||
          err.name === "NotAuthorizedException"
        ) {
          message =
            "Incorrect username or password, or the account doesn't exist.";
        }
        setType("error");
        setMessage(message);
        setIsShow(true);
        throw err;
      } finally {
        setIsLoading(false);
        apiRequestTrackerStore.decrement();
      }
    },
    [getRedirectUrl]
  );

  const onSocialLogin = useCallback(
    (provider: string) => {
      window.location.href = `/api/oauth/authorize?provider=${provider}&redirect_uri=${encodeURIComponent(
        getRedirectUrl()
      )}`;
    },
    [getRedirectUrl]
  );

  const redirectToAppOrCallback = (
    cb: Function,
    type: "signin" | "signup",
    value: boolean,
    redirectParams?: Record<string, unknown>
  ) => {
    const authPath = {
      signin: "/auth/login",
      signup: "/auth/signup",
    }[type];
    if (isWebView() && value) {
      let redirectUrl = getRedirectUrl(redirectParams);
      if (!redirectUrl.includes("?")) {
        redirectUrl += "?";
      }
      const queryString = `?redirectTo=${encodeURIComponent(
        redirectUrl + "&intent=nativeLogin"
      )}`;
      const domain = publicDomain();
      const href = `https://app.${domain}${authPath}${queryString}&intent=nativeLogin`;
      window.location.href = href;
    } else {
      cb();
    }
  };

  const customSetIsSignin = (
    value: boolean,
    redirectParams?: Record<string, unknown>
  ) => {
    redirectToAppOrCallback(
      () => {
        setIsSignin(value);
      },
      "signin",
      value,
      redirectParams
    );
  };

  const customSetIsSignup = (
    value: boolean,
    redirectParams?: Record<string, unknown>
  ) => {
    redirectToAppOrCallback(
      () => {
        setIsSignup(value);
      },
      "signup",
      value,
      redirectParams
    );
  };

  function getProtocolAndDomain(app_url: string) {
    const url = new URL(app_url);
    const protocolAndDomain = url.origin;
    return protocolAndDomain;
  }

  return {
    isSignin,
    isSignup,
    type,
    message,
    isShow,
    isLoading,
    source,
    isListingAction,
    coBrandedName,
    isDealNowDeal,
    isDealNowDealStart,
    isFavMark,
    isVehicleHistory,
    isLoanAction,
    setIsLoanAction,
    setIsVehicleHistory,
    setIsFavMark,
    setIsDealNowDeal,
    setCoBrandedName,
    setIsDealNowDealStart,
    setIsSignin: customSetIsSignin,
    setIsSignup: customSetIsSignup,
    isResetPassword,
    setIsResetPassword,
    setType,
    setMessage,
    setIsShow,
    setIsLoading,
    setIsCreateListing,
    isCreateListing,
    setIsListingAction,
    setSource,
    redirectUrl,
    setRedirectUrl,
    isAccountBannerVisible,
    onLogin,
    onSocialLogin,
  };
};

export default useAuth;
