import { useRouter } from "next/router";
import moment from "moment-timezone";
import { MAX_YEAR, MIN_YEAR } from "@/libs/constants";
import { SellerListing } from "@/interfaces/listing/SellerListing";

export const setScrollHidden = () => {
  if (document.body.style.overflow !== "hidden") {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflowY = "scroll";
  }
};

export const safeJsonParse = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return null;
  }
};

export const safeUrl = (url: string, base: string | undefined): string => {
  try {
    if (base && base.trim().length > 0 && base !== "/")
      return new URL(url, base).toString();
  } catch (e: any) {
    console.log(`safeUrl([${url}], [${base}]) failed - ${e.message}`);
  }
  return url;
};

export const getEmbedYoutubeUrl = (url: string): string | undefined => {
  const validYoutubeURL =
    url && (url.includes("youtube") || url.includes("youtu.be"));
  if (validYoutubeURL) {
    let videoId = null;
    if (url.includes(".be/")) {
      videoId = url.split(".be/")[1];
    } else if (url.includes("watch?v=")) {
      videoId = url.split("watch?v=")[1];
    } else if (url.includes("shorts")) {
      videoId = url.split("shorts/")[1];
    }

    if (videoId) {
      const ampersandPosition = videoId.indexOf("&");
      if (ampersandPosition !== -1) {
        videoId = videoId.substring(0, ampersandPosition);
      }

      const iframeUrl = `https://www.youtube.com/embed/${videoId}`;
      return iframeUrl;
    }
    return "";
  }
};

export const parseBgColor = (color: string): string => {
  if (color) {
    if (color.toLowerCase() == "white") {
      return "bg-[#FFFFFF]";
    } else if (color.toLowerCase() == "dark blue") {
      return "bg-[#2F3B55]";
    } else if (color.toLowerCase() == "light blue") {
      return "bg-[#DBF4FA]";
    } else if (color.toLowerCase() == "grey") {
      return "bg-[#EFF0F6]";
    } else if (color.toLocaleLowerCase() == "light grey") {
      return "bg-[#F9F9F9]";
    }
    return "bg-[#B2E8DE]";
  }
  return "";
};

export const parseContentBlockImagesDimensions = (data: any): string => {
  const imageDimensionCss: string[] = [];

  if (data?.ImageWidth) {
    imageDimensionCss.push(`sm:w-[${data.ImageWidth}px]`);
  } else if (data?.ImageHeight) {
    imageDimensionCss.push(`sm:h-[${data.ImageWidth}px]`);
  } else {
    imageDimensionCss.push(`sm:w-[225px]`);
  }

  return imageDimensionCss.join(" ");
};

export const parseOverrides = (overrides: any[]): string => {
  const overrideMappings: Record<string, string> = {
    "Vertical Spacing Bottom 30px / 18px mobile": "sm:pb-[30px] pb-[18px]",
    "Vertical Spacing Top 30px / 18px mobile": "sm:pt-[30px] pt-[18px]",
    "Vertical Spacing Top 60px / 32px mobile": "sm:pt-[60px] pt-[32px]",
    "Vertical Spacing Bottom 60px / 32px mobile": "sm:pb-[60px] pb-[32px]",
  };

  const additionalCss: string[] = overrides
    .map((override) => overrideMappings[override.attributes.Name])
    .filter(Boolean);

  return additionalCss.join(" ");
};

export const parseTitleColor = (color: string): string => {
  if (color.toLowerCase() == "dark blue") {
    return "text-[#FFF]";
  } else {
    return "text-[#333]";
  }
};

export const parseSubTitleColor = (color: string): string => {
  if (color.toLowerCase() == "dark blue") {
    return "text-[#FBFBFB]";
  } else {
    return "text-[#4F4F4F]";
  }
};

export const parseContentColor = (color: string): string => {
  if (color.toLowerCase() == "dark blue") {
    return "text-white";
  } else {
    return "text-[#4F4F4F]";
  }
};

export const parseListsColor = (color: string): string => {
  if (color.toLowerCase() == "white") {
    return "bg-[#FFFFFF]";
  } else if (color.toLowerCase() == "dark blue") {
    return "bg-[#2F3B55]";
  } else if (color.toLowerCase() == "grey") {
    return "bg-[#EFF0F6]";
  } else if (color.toLocaleLowerCase() == "light grey") {
    return "bg-[#F9F9F9]";
  }
  return "bg-[#B2E8DE]";
};

export const parseTextColor = (color: string): string => {
  if (color == "White") {
    return "text-[#fff]";
  } else if (color == "Dark Blue") {
    return "text-[#45577d]";
  } else if (color == "Grey") {
    return "text-[#eff0f6]";
  }
  return "text-[#b2e8de]";
};

export const parseWidth = (width: string): string => {
  let formatted: string = "";
  if (width) {
    formatted = width
      .replace(/w Full/g, "w-full lg:max-w-[1140px] px-[8%] lg:px-0 mx-auto")
      .replace(/w (\d+) pct/g, "w-[$1%]")
      .replace(/w (\d+) px/g, "w-[$1px]")
      .replace(/w (\d+) pt/g, "w-[$1pt]");
    if (width == "w 80 pct") {
      return "w-full td:w-[80%]";
    } else if (width == "w 60 pct") {
      return "w-full td:w-[60%]";
    } else if (width == "w 50 pct") {
      return "w-full td:w-[50%]";
    } else if (width == "w 40 pct") {
      return "w-full td:w-[40%]";
    } else if (width == "w 33 pct") {
      return "w-full td:w-[33%]";
    } else if (width == "w 25 pct") {
      return "w-full td:w-[25%]";
    }
  }
  return formatted;
};

export const parseTitle = (type: string, title?: string): string => {
  if (checkDealNowPage()) {
    return "text-[#4F4F4F] text-3xl sm:text-[40px] font-bold";
  } else if (type == "H1") {
    return "text-[56px] sm:text-[56px] font-bold leading-[45px] sm:leading-[3rem] sm:leading-[4rem]";
  } else if (type == "H2") {
    if (
      title == "Watch the first PrivateAuto transaction, live!" ||
      title == "Download the app."
    ) {
      return "text-3xl md:text-[45px] font-semibold md:leading-[55px]";
    } else if (
      title == "Take the guesswork out of the sale" ||
      title == "Feel confident with identity verification" ||
      title == "Transfer funds anywhere, instantly" ||
      title == "Frequently Asked Questions"
    ) {
      return "text-3xl md:text-[40px] font-semibold md:leading-[55px]";
    } else if (checkAboutPage() || checkContactPage()) {
      return "text-3xl";
    } else if (title == "How it Works") {
      return "text-3xl sm:text-[40px] font-bold";
    } else {
      return "text-3xl font-semibold";
    }
  } else if (type == "H3") {
    if (checkBlogPage()) {
      return "text-lg sm:text-2xl font-bold";
    } else if (checkAboutPage()) {
      return "text-sm";
    } else if (checkSellPage()) {
      return "text-3xl font-semibold";
    } else {
      return "text-2xl";
    }
  } else if (type == "H4") {
    if (title == "Sell or buy a vehicle privately.") {
      return "text-[30px]";
    } else {
      return "text-lg ";
    }
  } else if (type == "H5") {
    return "text-base font-medium";
  } else {
    return "text-sm font-medium";
  }
};

export const parseSimplifiedTitle = (type: string): string => {
  if (type == "H1") {
    return "text-[40px] sm:text-[56px] font-bold leading-[45px] sm:leading-[4rem]";
  } else if (type == "H2") {
    return "text-xl sm:text-[25px] font-normal";
  } else if (type == "H3") {
    if (checkBlogPage()) {
      return "textl-lg sm:text-2xl font-bold";
    } else {
      return "textl-lg sm:text-2xl font-normal";
    }
  } else if (type == "H4") {
    return "text-lg font-medium";
  } else if (type == "H5") {
    return "text-base font-medium";
  } else {
    return "text-sm font-medium";
  }
};

export const parseListsTitle = (type: string): string => {
  if (type == "H1") {
    return "text-[56px] font-extrabold sm:leading-[3rem] md:leading-[4rem]";
  } else if (type == "H2") {
    return "text-3xl font-semibold";
  } else if (type == "H3") {
    if (checkBlogPage()) {
      return "textl-lg sm:text-2xl font-bold";
    } else {
      return "textl-lg sm:text-2xl font-normal";
    }
  } else if (type == "H4") {
    return "text-[25px] font-medium";
  } else if (type == "H5") {
    return "text-base font-medium";
  } else {
    return "text-sm font-medium";
  }
};

export const parseContent = (content: string): string => {
  let converted: string = content
    .replace(
      /####\s(.*?)(?:\n|$)/g,
      '<h4 class="text-[22px] text-[#333] font-medium">$1</h4>\n'
    )
    .replace(
      /###\s(.*?)(?:\n|$)/g,
      '<h3 class="text-2xl text-[#333] font-bold">$1</h3>\n'
    )
    .replace(
      /##\s(.*?)(?:\n|$)/g,
      '<h2 class="text-3xl md:text-[40px] text-[#333] font-semibold">$1</h2>\n'
    )
    .replace(/\n/g, "<br/>")
    .replace(
      /\[(.*?)\]\((.*?)\)/g,
      '<a href="$2" class="text-[#00b3de] hover:text-[#007bff] hover:underline">$1</a>'
    );
  if (checkHomePage()) {
    converted = converted.replace(
      /<br\/>-/g,
      '<br/><i class="fas fa-check text-[#0b9709] max-h-[18px] inline-block"></i>'
    );
  }
  return converted;
};

export const parseContentBlockContent = (content: string): string => {
  let data: string = "";
  if (checkBlogPage()) {
    data = content
      .replace(/ {3,}/g, "&nbsp;&nbsp;&nbsp;")
      .replace(
        /\[(.+?)\]\((.+?)\)/g,
        '<a href="$2" class="text-[15px] text-[#1a1a1a] hover:text-[#00b3de] hover:underline">$1</a>'
      );
  } else {
    data = content.replace(
      /\[(.+?)\]\((.+?)\)/g,
      '<a href="$2" class="text-[#00b3de] hover:underline">$1</a>'
    );
  }
  let converted: string = data

    .replace(/_(.*?)_/g, "<i>$1</i>")
    .replace(/\*\*(.*?)\*\*/g, '<span class="font-bold">$1</span>')
    .replace(
      /####\s(.*?)(?:\n|$)/g,
      '<h4 class="text-[22px] font-medium">$1</h4>\n'
    )
    .replace(/###\s(.*?)(?:\n|$)/g, '<h3 class="text-2xl font-bold">$1</h3>\n')
    .replace(
      /##\s(.*?)(?:\n|$)/g,
      '<h2 class="text-3xl md:text-[40px] font-semibold">$1</h2>\n'
    )
    .replace(/\n/g, "<br/>");
  //"View All" string conversion to "View All ->"
  converted = converted
    .replace(
      /(View All)/gi,
      '<span class="text-[#00b3de] text-xs font-semibold">$1&nbsp;&nbsp;&nbsp;</span><span class="text-xl text-[#999]">→</span>'
    )
    .replace(
      /(Browse used cars for sale)/gi,
      '<span class="text-[#00b3de]">$1</span>'
    );

  const processedContent = converted
    .split("<br/>")
    .map((line) => {
      if (line.startsWith("-")) {
        return `<div class='pl-5 flex'><div>•</div><div class='pl-2'>${line
          .substring(1)
          .trim()}</div></div>`;
      } else {
        return line;
      }
    })
    .reduce(
      (acc, line) => acc + (line.includes("<div") ? line : line + "<br/>"),
      ""
    );
  return processedContent;
};

export const getLinkCountFromContent = (content: string): number => {
  if (content == null) {
    return 0;
  } else {
    const linkRegex = /\[(.*?)\]\((.*?)\)/g;
    const matches = content.match(linkRegex);
    const count: number = matches ? matches.length - 1 : 0;
    return count;
  }
};

export const parseAlignment = (alignment: string, title?: any): string => {
  if (alignment == "Left") {
    return "text-left";
  } else if (alignment == "Center") {
    return "text-center";
  } else if (alignment == "Right") {
    return "text-right";
  } else if (title == "Have a vehicle you want to sell?") {
    return "text-center";
  } else {
    return "text-left";
  }
};

type Alignment = "Left" | "Center" | "Right";

export const parseContainerAlignment = (alignment: Alignment): string => {
  const alignmentMap: Record<Alignment, string> = {
    Left: "justify-start",
    Center: "justify-center",
    Right: "justify-end",
  };
  return alignmentMap[alignment] || "";
};

export const getUnit = (unit: string): string => {
  let converted: string = "";
  if (unit == "pct") {
    converted = "%";
  } else {
    converted = unit;
  }
  return converted;
};

export const getTableHeaderWidth = (data: any): string => {
  const unit = getUnit(data?.Unit || "");
  const width = "w-[" + data?.Width + unit + "]";
  return width;
};

export const checkEsilyList = (title: string, style: string): Boolean => {
  if (title == "Easily list, meet, and get paid." && style == "H2") {
    return true;
  } else {
    return false;
  }
};

export const checkGuesswork = (title: string): Boolean => {
  if (title == "SIMPLE") {
    return true;
  } else {
    return false;
  }
};

export const checkHowItWorksPage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "how-it-works";
};

export const checkIfOrganiazationalSchemaPages = () => {
  const router = useRouter();
  const path = router.pathname;
  const schemaPages = [
    "/",
    "/buy",
    "/sell",
    "/dealnow",
    "/how-it-works",
    "/pricing",
    "/contact",
    "/about",
  ];
  return schemaPages.includes(path) || path.startsWith("/blog/");
};

export const checkDealNowPage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "dealnow";
};

export const checkNewSellPages = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[1] : "";
  return slug == "carmigo" || slug == "carwiser";
};

export const checkCarTransportGuidePage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "car-transport-guide";
};

export const checkUsedVehicleInspectionGuidePage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "used-vehicle-inspection-guide";
};

export const checkUsedCarWarrantyGuidePage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "used-car-warranty-guide";
};

export const checkUsedCarSalesTaxGuidePage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "used-car-sales-tax-guide";
};

export const checkFinancingCarsPage = () => {
  const router = useRouter();
  const asPath = router.asPath;
  return asPath.includes("financing/cars");
};

export const checkNewsAnnouncingPage = () => {
  const router = useRouter();
  const asPath = router.asPath;
  return asPath.includes("news/announcing-in-app-car-financing");
};

export const checkStatesPage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "states";
};

export const checkHomePage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "";
};

export const checkSellPage = () => {
  const router = useRouter();
  const slugArray = router.query.slug || [];
  return slugArray.length === 1 && slugArray[0] === "sell";
};

export const checkNativePage = () => {
  const router = useRouter();
  const slugArray = router.query.slug || [];
  return slugArray.length > 1 && slugArray[0] === "native";
};

export const checkNativeSellPage = () => {
  const router = useRouter();
  const slugArray = router.query.slug || [];
  return slugArray.length === 2 && slugArray[1] === "sell";
};

export const checkNativeBuyPage = () => {
  const router = useRouter();
  const slugArray = router.query.slug || [];
  return slugArray.length === 2 && slugArray[1] === "buy";
};

export const checkNativeVintagePage = () => {
  const router = useRouter();
  const { slug } = router.query;
  return (
    Array.isArray(slug) &&
    slug.length === 3 &&
    slug.includes("vintage") &&
    slug.includes("sell") &&
    slug.includes("native")
  );
};

export const checkNativeDetailsPage = () => {
  const router = useRouter();
  const { slug } = router.query;
  return (
    Array.isArray(slug) &&
    slug.length === 3 &&
    slug.includes("details") &&
    slug.includes("sell") &&
    slug.includes("native")
  );
};

export const checkSellDetailPage = () => {
  const router = useRouter();
  const { slug } = router.query;
  return (
    Array.isArray(slug) &&
    slug.length === 2 &&
    slug.includes("details") &&
    slug.includes("sell")
  );
};

export const checkSellVintagePage = () => {
  const router = useRouter();
  const { slug } = router.query;
  return (
    Array.isArray(slug) &&
    slug.length === 2 &&
    slug.includes("vintage") &&
    slug.includes("sell")
  );
};

export const checkListingDetailsPage = () => {
  const router = useRouter();
  return router.pathname.includes("listing/");
};

export const checkFinancingPage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "financing";
};
export const checkCompetitorsPage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "competitors";
};

export const checkBuyPage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "buy";
};

export const isMainBuyPage = () => {
  const router = useRouter();
  if (router.asPath.includes("?")) {
    const pathname = router.asPath.split("?")[0];
    return pathname === "/buy";
  }

  return router.asPath === "/buy";
};

export const checkCarInsurancePage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "car-insurance";
};

export const checkBlogPage = () => {
  const router = useRouter();
  const asPath = router.asPath;
  return asPath.startsWith("/blog");
};
export const checkBlogIndexPage = () => {
  const router = useRouter();
  const asPath = router.asPath;
  return asPath === "/blog";
};

export const checkAboutPage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "about";
};

export const checkIfPricingPage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "pricing" || slug == "pricing-test";
};

export const checkContactPage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "contact";
};

export const checkEscrowServiceForUsedCarTransactionsPage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "escrow-service-for-used-car-transactions";
};

export const checkBuyCityPage = () => {
  const router = useRouter();
  const asPath = router.asPath;
  return asPath.startsWith("/buy/");
};

export const checkTranportCarsPage = () => {
  const router = useRouter();
  const asPath = router.asPath;
  return asPath.includes("transport/cars");
};

export const checkInsurancePage = () => {
  const router = useRouter();
  const asPath = router.asPath;
  return asPath.includes("insurance/cars");
};

export const checkInsuranceCarsPage = () => {
  const router = useRouter();
  const asPath = router.asPath;
  return asPath.includes("insurance/cars");
};

export const checkRunbuggyTestPage = () => {
  const router = useRouter();
  const slug: string = router.query.slug ? router.query.slug[0] : "";
  return slug == "runbuggy-test";
};

export const checkIsHeader = (colData: any): Boolean => {
  let isHeader: Boolean = false;
  for (let item of colData) {
    if (item.__component == "sub-elements.cell-header") {
      isHeader = true;
      break;
    }
  }
  return isHeader;
};

export const getAttributeFromFilterField = (filterField: string): string => {
  if (filterField == "Exterior color") {
    return "exteriorColor";
  } else if (filterField == "Interior color") {
    return "interiorColor";
  } else if (filterField == "Trim") {
    return "trim";
  } else if (filterField == "Fuel type") {
    return "fuel";
  } else if (filterField == "Transmission") {
    return "transmission";
  } else if (filterField == "Drive type") {
    return "driveType";
  } else if (filterField == "Cylinders") {
    return "cylinders";
  } else return "";
};

export const isMobileDevice = () => {
  const userAgent = typeof window === "undefined" ? "" : navigator.userAgent;
  const test = /Mobi/.test(userAgent);
  return test;
};

export const checkSequentTwoContent = (
  firstContent: any,
  secondContent: any
) => {
  if (
    firstContent?.Color == secondContent?.Color &&
    firstContent?.Color != "White"
  ) {
    return true;
  } else {
    return false;
  }
};

export const constructUrl = (base: string, slug: string | undefined) => {
  return new URL(slug ?? "", base).toString();
};

export const constructBlogUrl = (base: string, slug: string | undefined) => {
  return new URL(`/blog/${slug}`, base || "/").toString(); //  `${base}/blog/${slug}`;
};

export const modifyHtml = (html: string) => {
  // const modifiedHtml = html.replace(/"(?=<script)|(?<=<\/script>)"/g, "");
  return html.replace(/<script.*(<\/script>|\/ *>)/g, "");
};

export const checkZipCodeIsValid = (zipCode: string): boolean => {
  const numericRegex = /^[0-9]+$/;
  return numericRegex.test(zipCode) && zipCode.length === 5;
};

export const checkYearIsValid = (year: string): boolean => {
  const numericRegex = /^[0-9]+$/;
  if (numericRegex.test(year)) {
    return parseInt(year) > MIN_YEAR - 1 && parseInt(year) < MAX_YEAR + 1;
  } else {
    return false;
  }
};

export const checkEmailIsValid = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const convertRedirectCode = (code: string): number => {
  const numericPart = code.match(/\d+/)?.[0];
  return Number(numericPart || code);
};

export const getGridRows = (length: number): string => {
  return "grid-rows-25 vs:grid-rows-16 sm:grid-rows-10";
};

export const svgLoader = ({ src }: any) => {
  return src;
};

export const getNumberedList = (data: string): string => {
  const olRegex = /<ol>(.*?)<\/ol>/gs;
  let result = data;
  let match;

  while ((match = olRegex.exec(data)) !== null) {
    const [, olContent] = match;
    const liRegex = /<li>(.*?)<\/li>/gs;
    let localSequence = 1;
    let replacedContent = olContent.replace(liRegex, (_, liContent) => {
      return `<li class="leading-8">${localSequence++}. ${liContent}</li>`;
    });
    result = result.replace(
      olContent,
      `<ol class="py-8">${replacedContent}</ol>`
    );
  }

  return result;
};

export const addBulletPointsToLiTags = (data: string): string => {
  const modifiedData = data.replace(/<ul>(.*?)<\/ul>/gs, (match, list) => {
    const modifiedList = list.replace(/<li>/g, "<li>");
    return `<ul class="px-3.5 bulletedIcon">${modifiedList}</ul>`;
  });
  return modifiedData;
};

export const parseBlogData = (html: string): string => {
  let convertedHtml: string = html
    .replace(
      /<a\b[^>]*>(.*?)<\/a>/g,
      '<a class="text-[#00b3de] hover:underline"$&</a>'
    )
    .replace(
      /<h2\b[^>]*>(.*?)<\/h2>/g,
      '<h2 class="text-[23px] font-extrabold pt-8 pb-4">$1</h2>'
    )
    .replace(
      /<h3\b[^>]*>(.*?)<\/h3>/g,
      '<h3 class="text-[20px] font-extrabold py-8">$1</h3>'
    )
    .replace(/<p>(.*?)<\/p>/gs, '<p class="py-2">$1</p>')
    .replace(/<figure([^>]*)>/g, '<figure class="py-10"$1>');
  return getNumberedList(addBulletPointsToLiTags(convertedHtml));
};

export const getPosition = (str: string): string => {
  const regex = /in\s([A-Za-z]+)/i;
  const match = str.match(regex);
  const result = match ? match[1] : "";
  return result;
};

export const formatCurrency = (
  value: number,
  locale: string,
  currency: string
): string => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
};

export const generateListingTitle = (listing: SellerListing) => {
  return [
    listing?.RegistrationYear,
    listing?.CarMake,
    listing?.CarModel,
    "For Sale in",
    listing?.listingLocation?.city && `${listing?.listingLocation?.city},`,
    listing?.listingLocation?.state,
    "for",
    `${formatCurrency(listing?.Price, "en-US", "USD")}`,
    "|",
    "PrivateAuto",
  ]
    .filter(Boolean)
    .join(" ");
};

export const generateListingDescription = (listing: SellerListing) => {
  return [
    "Used",
    listing?.RegistrationYear,
    listing?.CarMake,
    listing?.CarModel,
    "For Sale by owner in",
    listing?.listingLocation?.city && `${listing?.listingLocation?.city},`,
    listing?.listingLocation?.state,
    "for",
    `${formatCurrency(listing?.Price, "en-US", "USD")}`,
    "The safe and simple way to buy cars for sale by owner | PrivateAuto",
  ]
    .filter(Boolean)
    .join(" ");
};

export const slugify = (str: string) => {
  return str
    .replace(/\s+/g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "")
    .replace(/-+/g, "-")
    .toLowerCase();
};

export const convertBgPaymentCalculator = (color: string) => {
  if (color.toLowerCase() == "white") {
    return "bg-[#FFFFFF]";
  } else if (color.toLowerCase() == "dark blue") {
    return "bg-[#2F3B55]";
  } else if (color.toLowerCase() == "light grey") {
    return "bg-[#F9F9F9]";
  } else if (color.toLowerCase() == "grey") {
    return "bg-[#EFF0F6]";
  } else if (color.toLowerCase() == "green") {
    return "bg-[#B2E8DE]";
  } else {
    return "bg-[#45577d]";
  }
};

export const convertStylePaymentCalculator = (style: string) => {
  if (style == "Half") {
    return "h-[350px]";
  } else if (style == "Full") {
    return "h-[1300px] sm:h-[800px]";
  } else {
    return "h-[300px]";
  }
};

export const parseSubTitle = (str: any) => {
  if (str == null) {
    return "";
  } else {
    const converted_str = str.replace(". ", ".<br/>");
    return converted_str;
  }
};

export const getDateStrings = (date: Date, useUtcTime = false) => {
  const year = useUtcTime
    ? date.getUTCFullYear().toString()
    : date.getFullYear().toString();
  const month = useUtcTime
    ? (date.getUTCMonth() + 1).toString()
    : (date.getMonth() + 1).toString();
  const day = useUtcTime
    ? date.getUTCDate().toString()
    : date.getDate().toString();
  const hour = useUtcTime
    ? date.getUTCHours().toString()
    : date.getHours().toString();
  const minutes = useUtcTime
    ? date.getUTCMinutes().toString()
    : date.getMinutes().toString();

  return {
    year,
    month: month.length === 2 ? month : "0" + month,
    day: day.length === 2 ? day : "0" + day,
    hour: hour.length === 2 ? hour : "0" + hour,
    minutes: minutes.length === 2 ? minutes : "0" + minutes,
  };
};

export const convertToOutlookDate = (date: Date): string => {
  const dates = getDateStrings(date, false);

  return `${dates.year}-${dates.month}-${dates.day}T${dates.hour}:${dates.minutes}`;
};

export const convertLocationToString = (location: any): string => {
  return `${location.address} ${location.city} ${location.state} ${location.zipcode}`;
};

export const convertToGoogleDate = (date: Date): string => {
  const dates = getDateStrings(date, false);
  return `${dates.year}${dates.month}${dates.day}T${dates.hour}${dates.minutes}00`;
};

export const capitalizeWords = (str: string): string => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const checkColumnsHairline = (data: any, index: number): boolean => {
  if (
    data.length == index ||
    index + 1 == data.length ||
    data[index + 1]?.__component != "page-elements.column"
  ) {
    return false;
  } else {
    return true;
  }
};

export const GoogleTimeFormat = (startTimeString: string) => {
  const offsetHours = 4;

  const formatted_result =
    moment
      .tz(startTimeString, "UTC")
      .add(offsetHours, "hours")
      .format("YYYYMMDDTHHmmss") + "Z";
  return formatted_result;
};

export const parseCTAColor = (color: string): string => {
  if (color == "White") {
    return "bg-[#FFFFFF] text-[#00A0C7] border border-[#00A0C7]";
  } else if (color == "Dark Blue") {
    return "bg-[#2F3B55] text-[#ffffff]";
  } else if (color == "Light Grey") {
    return "bg-[#F9F9F9] text-[#333333]";
  } else if (color == "Grey") {
    return "bg-[#EFF0F6] text-[#333333]";
  } else if (color == "Green") {
    return "bg-[#B2E8DE] text-[#333333]";
  } else if (color == "Sky Blue") {
    return "bg-[#00A0C7] text-[#ffffff]";
  } else {
    return "";
  }
};

export const convertCTAAction = (script: string, label: string): string => {
  const removedScript = script.replace(/<script.*?<\/script>\n/, "");
  const modifiedScript = removedScript
    .replace(/openSavvyBtn/g, "openSavvyBtn1")
    .replace("Get Insurance", label);
  return modifiedScript;
};

export const getDefaultSearchInfo = () => {
  return {
    AllowFilterChanges: true,
    DisplayRows: 6,
    Filters: null,
    LinkText: "See More",
    LinkURL: "/native/search",
    MaxListings: null,
    ShowSimilarListings: true,
    SortOrder: null,
    Title: "Browse Vehicles",
    TitleStyle: "H2",
    TrackUrl: true,
    id: 1,
    ShowPagination: true,
    __component: "app-forms.vehicle-search",
  };
};

export const isWebView = () =>
  window !== undefined && window.navigator.userAgent.includes("gonative");

export const cleanupCanonicalUrl = (url: string | undefined) => {
  if (url) {
    let cleanUrl = url.split("?")[0].split("#")[0];
    return cleanUrl.endsWith("/") ? cleanUrl.slice(0, -1) : cleanUrl;
  }
  console.log(`cleanupCanonicalUrl: [${url}]`);
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const isIphone = () => {
  return typeof window !== "undefined" && /iPhone/.test(navigator.userAgent);
};

export const buildSearchQueryUrl = (params: any) => {
  const query = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        query.append(`${key}[${index}]`, item);
      });
    } else {
      query.append(key, value as string);
    }
  });

  return `?${query.toString()}`;
};

export const convertSearchToAppSearch = (query: Record<string, string>) => {
  const defaults = {
    searchKey: "",
    make: "",
    models: "",
    bodyType: "",
    minYear: "1910",
    maxYear: `${new Date().getFullYear() + 1}`,
    minMiles: "0",
    maxMiles: "300000",
    sort: "Newest",
    trim: "",
    exteriorColor: "",
    interiorColor: "",
    fuelType: "",
    transmission: "",
    driveType: "",
    minPrice: "0",
    maxPrice: "1000000",
    cylinders: "",
    radius: "50",
  } as any;
  const arrayValues = [
    "make",
    "models",
    "bodyType",
    "trim",
    "exteriorColor",
    "interiorColor",
    "fuelType",
    "transmission",
    "driveType",
    "cylinders",
  ];
  const booleanValues = ["isVerified"];
  const mappings: Record<string, string> = {
    searchKey: "_search",
    make: "CarMake",
    models: "CarModel",
    bodyType: "BodyStyle",
    minYear: "YearFrom",
    maxYear: "YearTo",
    minMiles: "MileageMin",
    maxMiles: "MileageMax",
    isVerified: "Verified",
    sort: "sort",
    trim: "Trim",
    exteriorColor: "ExteriorColor",
    interiorColor: "InteriorColor",
    fuelType: "Fuel",
    transmission: "Transmission",
    driveType: "DriveType",
    minPrice: "PriceMin",
    maxPrice: "PriceMax",
    cylinders: "Cylinders",
    radius: "radius",
  };
  return Object.entries(query).reduce((acc, [key, value]) => {
    if (!value || defaults[key] === value) {
      return acc;
    }
    return {
      ...acc,
      ...(mappings[key] && {
        [mappings[key]]:
          arrayValues.includes(key) && !Array.isArray(value)
            ? [value]
            : booleanValues.includes(key)
            ? Boolean(value || false)
            : value,
      }),
    };
  }, {});
};

export const delay = (delayInMs = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, delayInMs);
  });
};
