import { SignupSchema, useSignupForm } from "@/hooks/useSignupForm";
import { useCallback, useState } from "react";
import Image from "next/image";
import StrongPassword from "./StrongPassword";
import { SocialSignin } from "./SocialSignin";
import { AuthenticationScreenProps, AuthenticationType } from "./types";
import { Auth } from "aws-amplify";
import { useAuthValue } from "@/contexts/contextAuth";
import { delay } from "@/libs/utils";
import { useApiRequestTracker } from "@/stores/api-request-tracker.store";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { PostHogFlags } from "@/interfaces/PostHogFlags";

export const Signup = ({ changeScreen }: AuthenticationScreenProps) => {
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const apiRequestTrackerStore = useApiRequestTracker();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useSignupForm();
  const proveVerificationEnabled = useFeatureFlagEnabled(
    PostHogFlags.ProveVerification
  );
  const { onLogin, setType, setMessage, setIsShow } = useAuthValue();
  const passwordValue = watch("password", "");
  const onSubmit = useCallback(
    handleSubmit(async ({ email, password }: SignupSchema) => {
      apiRequestTrackerStore.increment();
      try {
        await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email,
            family_name: "",
            given_name: "",
          },
        });
        window?.dataLayer &&
          window.dataLayer.push({
            event: "Account Created",
          });
        window.fbq("track", "Create Account");
        window.rdt("track", {
          currency: "USD",
          transactionId: email,
          value: 100,
        });
        window.uipe("CreateAccout", {
          userEmail: email,
          name: "",
        });
        const successUrl = await onLogin(email, password);
        setType("success");
        setMessage(
          "You have successfully created an account, please check your email to verify your account."
        );
        setIsShow(true);
        await delay(500);
        window.location.href = successUrl;
      } catch (err: any) {
        setType("error");
        setMessage(err.message);
        setIsShow(true);
      }
      apiRequestTrackerStore.decrement();
    }),
    [handleSubmit]
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="text-[2rem] text-[#212529] font-bold">
        Create an account
      </div>
      <div className="text-base text-[#212529] font-medium py-2">
        Already have an account?&nbsp;
        <span
          className="text-[#00b3de] underline cursor-pointer"
          role="link"
          onClick={() => changeScreen(AuthenticationType.Signin)}
        >
          Sign in
        </span>
      </div>
      <div className="py-2">
        <input
          type="email"
          className="border-[#9797aa] focus:border-[#00a0c7] form-control w-full block px-4 py-2 text-base font-medium bg-white bg-clip-padding border border-solid  rounded m-0"
          placeholder="Email address"
          {...register("email")}
        />
        {errors.email && (
          <div className="text-xs text-left text-[#ed0a0a] pt-2">
            {errors.email.message}
          </div>
        )}
      </div>
      <div className="relative py-2">
        <input
          type={`${showPassword ? "text" : "password"}`}
          className="border-[#9797aa] focus:border-[#00a0c7] form-control w-full block px-4 py-2 text-base font-medium bg-white bg-clip-padding border border-solid  rounded m-0"
          placeholder="Password"
          {...register("password")}
        />
        {errors.password && (
          <div className="text-xs text-left text-[#ed0a0a] pt-2">
            {errors.password?.message}
          </div>
        )}
        <StrongPassword password={passwordValue} />
        {showPassword ? (
          <Image
            className="absolute top-5 right-2"
            width={17}
            height={15}
            src={`/assets/eyeCrossedOut.svg`}
            alt="eye"
            onClick={() => setShowPassword(!showPassword)}
          />
        ) : (
          <Image
            className="absolute top-6 right-2"
            width={15}
            height={11}
            src={`/assets/eye.svg`}
            alt="eye"
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
      </div>
      <div className="py-2">
        <button
          className="w-full bg-[#00b3de] hover:opacity-80 text-[#fff] text-base font-bold py-3 px-4 border border-[#00a0c7] rounded cursor-pointer"
          type="submit"
        >
          Create account
        </button>
        {proveVerificationEnabled && (
          <div className="pt-5 px-4 pb-8 flex justify-center">
            <p className="text-[#808080] text-xs font-medium text-center w-[250px]">
              By clicking “Create account” your accept our{" "}
              <a
                href="https://privateauto.com/terms"
                target="_blank"
                className="underline font-bold"
              >
                Terms and conditions
              </a>
            </p>
          </div>
        )}
      </div>
      <hr className="text-[#c4c4c4] my-2" />
      <SocialSignin />
    </form>
  );
};
